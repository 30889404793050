import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import axiosInstance from '../../config/axios';
import { fuentes, color_formulario } from '../../config/theme/variables';
import { register_form } from '../../config/theme/textos_sp';
import sprite from '../../res/svgs/symbol-defs.svg';
import useAuth from '../../auth/useAuth';

// solo para ver el newpost - borrar
// import NewPost from '../post/newpost/NewPost';
import Spinner from '../loader/Spinner';
import ErrorMsj from '../error/ErrorMsj';

const Contenedor = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 30px auto;
  /* width: 300px; */
  position: relative;
  /* top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-shadow: 6px 7px 37px -12px rgba(72, 72, 72, 0.49);
  box-shadow: 6px 7px 37px -12px rgba(72, 72, 72, 0.49);
  border-radius: 8px;
  /* background-color: #16141b; */
  background-color: ${color_formulario.fondo};
  /* padding-left: 34px; */
  /* padding-bottom: 34px; */
  padding: 24px;
  align-items: center;
`;

/* ---------------------------------- NUEVO --------------------------------- */
const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 3rem;
  margin-top: 2.5rem;
`;
const Underline = styled.div`
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: -3px;
  transform: scaleX(0);
  width: 100%;
  height: 1px;
  background: #888888;
  transition: all 0.35s;
`;
const Label = styled.div`
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(2.5rem, -53%);
  transform-origin: 0% 0%;
  color: #88888877;
  padding: 0.25rem 0;
  pointer-events: none;
  transition: all 0.35s;
`;
const Icon = styled.svg`
  position: absolute;
  width: 23px;
  height: 23px;
  top: 50%;
  left: 2px;
  font-size: 1.675rem;
  transform: translateY(-46%);
  fill: #88888855;
  pointer-events: none;
  transition: all 0.35s;
`;
const Input = styled.input`
  width: 16rem;
  height: inherit;
  padding-left: 2.5rem;
  border: 0;
  border-bottom: 1px solid #88888855;
  outline: none;
  background: transparent;
  color: #555555;
  font-size: 1rem;
  transition: all 0.35s;
  letter-spacing: 0.75px;
  &:focus ~ ${Icon} {
    fill: #555555;
  }

  &:focus ~ ${Label} {
    /* background: #16141b; */
    color: #555555;
    transform: translate(0, -2.5rem) scale(0.875);
  }
  &:focus ~ ${Underline} {
    transform: scaleX(1);
  }

  &:not(input[value='']) ~ ${Label} {
    color: #555555;
    transform: translate(0, -2.5rem) scale(0.875);
  }

  &:not(input[value='']) ~ ${Underline} {
    color: #555555;
    transform: scaleX(1);
  }

  &:not(input[value='']) ~ ${Icon} {
    fill: #555555;
  }

  &:invalid:not(input[value='']) ~ ${Icon} {
    /* border-bottom: 0.05882rem solid ${(props) =>
      props.colorCampoInvalido}; */
    /* background-color: #ffa38433; */
    /* color: ${color_formulario.letra_activa}; */
    /* font-weight: 700; */
    /* letter-spacing: 0.75px; */
    /* font-size: 14px; */
    fill: #ff2442bb;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${color_formulario.fondo} inset !important;
    -webkit-text-fill-color: #555555 !important;
  }
`;

const Logo = styled.span`
  color: #e02401;
  /* font-family: 'Archivo Black', sans-serif; */
  font-family: 'Mitr', sans-serif;
  /* font-family: 'Suez One', serif; */
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 2px;
`;
/* -------------------------------------------------------------------------- */
// const PanelDer = styled.div`
//   /* width: 300px; */
//   height: 100%;
//   /* background-color: blue; */
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding-left: 34px;
//   /* align-items: center; */
// `;

const TextoWrapper = styled.div`
  /* margin-left: 20px; */
  /* margin-right: 65px; */
  /* margin-bottom: 10px; */
  /* padding-bottom: 20px; */
  /* margin: 40px 10px 10px 30px; */
`;
const Titulo = styled.h2`
  padding: 0;
  margin: 30px 0 10px 0;
  letter-spacing: 0px;
  width: 300px;
  font-weight: 700;
  line-height: 25px;
  /* background-color: pink; */
`;
const Subtitulo = styled.p`
  padding: 0;
  margin: 0;
  color: ${color_formulario.letra_activa};
  font-weight: 400;
  width: 270px;
`;
const Formulario = styled.form`
  position: relative;
`;
// const CampoWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin: 15px 0 15px 0;
//   font-weight: 500;
// `;
// const TextoInput = styled.input`
//   height: 33px;
//   width: 280px;
//   padding-left: 15px;
//   border-radius: 3px;
//   font-weight: 600;
//   border: none;
//   -webkit-box-shadow: 1px 1px 8px -1px rgba(202, 202, 202, 0.49);
//   box-shadow: 1px 1px 8px -1px rgba(202, 202, 202, 0.49);
//   outline: none;
//   color: ${color_formulario.letra_inactiva};

//   &:focus {
//     /* color: ${(props) => props.colorLetraSelec}; */
//     color: ${color_formulario.letra_activa};
//     //transform: translateY(-20px);
//     /* font-size: 0.7rem; */
//   }
//   &:focus::-webkit-input-placeholder {
//     color: transparent;
//   }
//   /* &:not(:focus)::-webkit-input-placeholder {
//     color: transparent;
//   } */

//   &::placeholder {
//     color: ${color_formulario.letra_inactiva};
//   }
//   &::-moz-placeholder {
//     color: ${color_formulario.letra_inactiva};
//   }
//   &::-webkit-input-placeholder {
//     color: ${color_formulario.letra_inactiva};
//   }
//   &::-ms-input-placeholder {
//     color: ${color_formulario.letra_inactiva};
//   }
//   &:-ms-input-placeholder {
//     color: ${color_formulario.letra_inactiva};
//   }
//   &:invalid:not(input[value='']) {
//     /* border-bottom: 0.05882rem solid ${(props) =>
//       props.colorCampoInvalido}; */
//     background-color: #ffa38433;
//     color: ${color_formulario.letra_activa};
//     /* font-weight: 700; */
//     letter-spacing: 0.75px;
//     font-size: 14px;
//   }
//   &:valid {
//     /* border-bottom: 0.05882rem solid ${(props) =>
//       props.colorCampoInvalido}; */
//     /* background-color: #b4f8c833; */
//     color: ${color_formulario.letra_activa};
//     /* font-weight: 700; */
//     letter-spacing: 0.75px;
//     font-size: 14px;
//   }
// `;
// const Etiqueta = styled.label`
//   font-size: 11px;
//   margin-bottom: 8px;
//   color: grey;
// `;
const Boton = styled.button`
  font-family: ${fuentes.primaria};
  /* background-color: ${color_formulario.boton_solido}; */
  background-color: #152d35;
  border: none;
  border-radius: 5px;
  width: 95px;
  height: 40px;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 20px 20px 5px;
  font-size: 15px;
  transition: all 0.2s;
  outline: none;
  &:hover:not(:disabled) {
    transform: translateY(-3px);
    cursor: pointer;
    background-color: #eb2c6a;
    box-shadow: 0 16px 32px #152d3522;
  }
  &:active {
    transform: translateY(-1px);
    cursor: pointer;
    box-shadow: 0 8px 16px #152d3522;
  }
  &:disabled {
    background-color: gray;
  }
`;

const Pie = styled.p`
  width: 285px;
  font-size: 12px;
  margin: 8px 0 37px 5px;
  color: ${color_formulario.letra_inactiva};
  font-weight: 400;
  /* background-color: pink; */
  text-align: right;
`;

const Resaltado = styled.span`
  color: #888888;

  /* transition: all 0.2s; */
  /* outline: none; */
  /* font-weight: 400; */
  &:hover {
    color: ${color_formulario.boton_solido};
    cursor: pointer;
    /* transform: scale(1.05); */
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 10px;
`;
const Loader = styled.div`
  width: 10%;
  height: 20%;
  right: 50px;
  bottom: 20px;
  position: absolute;
  /* top: 10px; */
  /* background-color: pink; */
`;

const LoginForm = () => {
  // const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const auth = useAuth();
  const history = useHistory();

  // si por ejemplo entro a la pagina payments, no logueado y quiero loguarme y que me redireccione a payments
  // const location = useLocation();
  // const previousObjectURL = location.state?.from || '/'; // el ? evita los errores que saltan al invocar from de una variable null

  const handleOnClickIngresar = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const { data } = await axiosInstance.post('/users/login', {
        email,
        password,
      });
      // console.log(data);
      if (typeof data.data.user !== 'undefined') {
        // console.log('entro');
        // console.log(data.data.user);
        auth.login({ ...data.data.user, token: data.token });
        // auth.login(data.data.user);
        // console.log(data.data);
        setLoading(false);
        // console.log(auth.user);
      }

      // console.log(data.data.user);
    } catch (error) {
      //https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/
      if (error.response) {
        // client received an error response (5xx, 4xx)
        let mensaje = 'Error de conexión, vuelva mas tarde. (500)';
        // console.log('1', error.response);
        if (error.response.status === 401) {
          // console.log(error);
          if (error.response.data.data && error.response.data.data.code === 7) {
            mensaje = 'Email no confirmado, por favor revise su casilla';
          } else {
            mensaje = 'Usuario o contraseña incorrectos';
          }
        }

        setError(mensaje);
        // console.log('1', error.response);
      } else if (error.request) {
        // client never received a response, or request never left
        //console.log('2', error.request);
        setError('Error de conexión, vuelva mas tarde.');
      } else {
        // anything else
        //console.log('3', error);
        setError('algo malo pasó.');
      }
      setLoading(false);
      // console.log(error.response.data);

      // setError(error.response.data.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    }
    // auth.login();
    // console.log();
    // history.push(previousObjectURL);
  };

  // const handleOnClickRegistrar = (event) => {
  //   event.preventDefault();

  //   // const previousObjectURL = location.state?.from || '/register';
  //   //auth.login();
  //   history.push('/register');
  // };

  const handleOnClickForgot = (event) => {
    event.preventDefault();

    // const previousObjectURL = location.state?.from || '/register';
    //auth.login();
    history.push('/forgotpassword');
  };

  return (
    <>
      {/* <NewPost></NewPost> */}
      <Contenedor>
        <TextoWrapper>
          <Titulo>
            Bienvenido a <Logo>bamed</Logo>!
          </Titulo>

          <Subtitulo>{register_form.subtitulo}</Subtitulo>
          {error && (
            <ErrorMessage>
              <ErrorMsj text={error}></ErrorMsj>
            </ErrorMessage>
          )}
        </TextoWrapper>
        <Formulario onSubmit={handleOnClickIngresar}>
          <Wrapper>
            <Input
              spellCheck="false"
              placeholder=" "
              required
              id="email"
              value={email}
              title="email"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            ></Input>
            <Underline></Underline>
            <Label>correo electrónico</Label>
            <Icon>
              <use href={sprite + '#icon-mail'} />
            </Icon>
          </Wrapper>
          <Wrapper>
            <Input
              spellCheck="false"
              placeholder=" "
              required
              title="contraseña"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
              pattern=".{8,}"
            ></Input>
            <Underline></Underline>
            <Label>contraseña</Label>
            <Icon>
              <use href={sprite + '#icon-key'} />
            </Icon>
          </Wrapper>

          {/* <CampoWrapper>
            <Etiqueta>{register_form.email}</Etiqueta>
            <TextoInput
              placeholder={register_form.email_ph}
              required
              id="email"
              value={email}
              title="xxxxx@xxxxx.xxx"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
              spellCheck={false}
            ></TextoInput>
          </CampoWrapper>
          <CampoWrapper>
            <Etiqueta>{register_form.password}</Etiqueta>
            <TextoInput
              placeholder={'Ingrese su contraseña'}
              type="password"
              required
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
            ></TextoInput>
          </CampoWrapper> */}
          <Pie>
            <Resaltado onClick={handleOnClickForgot}>
              {register_form.forgot}
            </Resaltado>
          </Pie>

          <Boton {...(loading ? { disabled: true } : {})}>
            {register_form.boton}
          </Boton>
          <Loader>
            {loading && <Spinner></Spinner>}
            {/* {<Spinner></Spinner>} */}
          </Loader>
        </Formulario>
        {/* <Pie>
            {register_form.pie_login}
            <Resaltado onClick={handleOnClickRegistrar}>
              {' '}
              {register_form.link}
            </Resaltado>
          </Pie> */}
      </Contenedor>
    </>
  );
};

export default LoginForm;
