import axios from 'axios';

/* ----------------------------------- DEV ---------------------------------- */

// const axiosInstance = axios.create({
//   baseURL: 'http://localhost:5000/api/v1/',
//   withCredentials: true, // para las cookies eliminar en deploy
//   // baseURL: '/api/v1',
// });
/* -------------------------------------------------------------------------- */

const axiosInstance = axios.create({
  baseURL: 'https://biotherm.herokuapp.com/api/v1',
  withCredentials: true, // para las cookies
});

export default axiosInstance;
