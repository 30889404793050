import React from 'react';
import { Redirect, Route } from 'react-router';
import useAuth from '../auth/useAuth';

const PublicRoute = ({ component: Component, ...rest }) => {
  //hay que darle un alias a component en mayuscula para que react lo tome como un componente

  const auth = useAuth();

  return (
    <Route {...rest}>
      {!auth.isLogged() ? <Component /> : <Redirect to="/" />}
    </Route>
    // desestructuramos props ({...props}). es equivalente a
    // <Route exact={props.exact} path={props.path} component={props.component} />
  );
};

export default PublicRoute;
