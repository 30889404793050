import React from 'react';
import styled from 'styled-components';
import sprite from '../../res/svgs/symbol-defs.svg';

const Contenedor = styled.div`
  box-shadow: rgba(149, 157, 165, 0.75) 0px 8px 24px;
  width: 100%;
  height: 100%;
  /* margin: 20px; */
  font-family: 'Montserrat', sans-serif;
  /* padding: 30px; */
  /* display: inline-block; // ajusta el div al contenido */
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: relative;
`;

const TituloWrapper = styled.div`
  position: absolute;
  margin-top: 20px;
  display: flex;
  width: 83%;
  top: 2px;
  left: 28px;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 20px; */
`;
const Titulo = styled.div`
  font-size: 20px;
  /* margin-top: 20px; */
  /* margin-left: 25px; */
  color: #888888;
  font-weight: 500;
`;

const Icon = styled.svg`
  width: 25px;
  height: 25px;
  margin-right: 15px;
  fill: #888888;
`;

const Tarjeta = ({ ico, tit, children }) => {
  return (
    <Contenedor>
      <TituloWrapper>
        <Icon>
          <use href={sprite + ico} />
        </Icon>
        <Titulo>{tit}</Titulo>
      </TituloWrapper>
      {children}
    </Contenedor>
  );
};

export default Tarjeta;
