import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

// import { dummy } from './objPrueba';
import { useSeriesAndStats } from '../../context/SeriesContext';
import Spinner from '../loader/Spinner';
import sprite from '../../res/svgs/symbol-defs.svg';

const Contenedor = styled.div`
  box-shadow: rgba(149, 157, 165, 0.75) 0px 8px 24px;
  width: 800px;
  min-height: 538px;
  /* margin: 20px; */
  font-family: 'Montserrat', sans-serif;
  padding: 30px;
  display: flex; // ajusta el div al contenido
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
`;

const Loader = styled.div`
  position: relative;
`;
const Grafico = styled.div`
  margin-top: 25px;
`;

const TituloWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  width: 83%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  top: 0;
  left: 20px;
`;
const Titulo = styled.div`
  font-size: 20px;
  /* margin-top: 20px; */
  /* margin-left: 25px; */
  color: #888888;
  font-weight: 500;
`;

const Icon = styled.svg`
  width: 25px;
  height: 25px;
  margin-right: 15px;
  fill: #888888;
`;

const ChartV2 = ({ ancho }) => {
  const { series, loading, unidad } = useSeriesAndStats();
  const [vmin, setVmin] = useState();
  const [vmax, setVmax] = useState();
  const [leyendaY, setLeyendaY] = useState(
    unidad ? `${unidad.nombre} [ ${unidad.unidad} ]` : ''
  );
  const [titulo, setTitulo] = useState(unidad ? `${unidad.nombre}` : '');

  useEffect(() => {
    // console.log(unidad);
    if (unidad) {
      setLeyendaY(`${unidad.nombre} [ ${unidad.unidad} ]`);
      setTitulo(`${unidad.nombre}`);
    }
    if (series) {
      // console.log(series);
      let auxVmin = series[0].stats[0].minValue;
      let auxVmax = series[0].stats[0].maxValue;

      series.forEach((element) => {
        if (element.stats[0].minValue < auxVmin) {
          auxVmin = element.stats[0].minValue;
        }
        if (element.stats[0].maxValue > auxVmax) {
          auxVmax = element.stats[0].maxValue;
        }
      });
      setVmin(
        Math.round(
          auxVmin >= 0 ? auxVmin - auxVmin * 0.1 : auxVmin + auxVmin * 0.1
        )
      );
      setVmax(
        Math.round(
          auxVmax >= 0 ? auxVmax + auxVmax * 0.1 : auxVmax - auxVmax * 0.1
        )
      );
      // console.log(series);
    }
  }, [series]);

  // useEffect(() => {
  //   console.log(vmin, vmax);
  // }, [vmin, vmax]);

  let options = {
    chart: {
      id: 'area-datetime',
      // type: 'area',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.1,
      },
      // height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },

    xaxis: {
      type: 'datetime',
      // min: new Date('01 Mar 2012').getTime(),
      tickAmount: 12,
      title: {
        text: 'Hora',
      },
      labels: {
        datetimeUTC: false,
      },
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },

    yaxis: {
      title: {
        text: leyendaY,
      },
      tickAmount: 10,
      min: vmin,
      max: vmax,
    },
    tooltip: {
      x: {
        format: 'HH:mm - dd/MM/yy',
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3.5,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: false,
      offsetY: -5,
      offsetX: -5,
    },
    noData: {
      text: 'No hay datos...',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.03,
        inverseColors: false,
        // stops: [0, 30],
      },
    },

    dataLabels: {
      enabled: false,
    },
    title: {
      text: titulo,
    },
  };

  return (
    <Contenedor>
      <TituloWrapper>
        <Icon>
          <use href={sprite + '#icon-area-graph'} />
        </Icon>
        <Titulo>Gráfico</Titulo>
      </TituloWrapper>
      {!loading && series ? (
        <Grafico>
          <Chart
            options={options}
            series={series}
            type={'area'}
            width={ancho}
          ></Chart>
        </Grafico>
      ) : (
        series && (
          <Loader>
            <Spinner></Spinner>
          </Loader>
        )
      )}
    </Contenedor>
  );
};

export default ChartV2;
