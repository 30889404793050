import React from 'react';
import styled from 'styled-components';

const Contenedor = styled.div`
  width: 270px;
  min-height: 70px;
  color: #ffffffff;
  font-size: 18px;
  font-weight: 600;
  background-color: #e63946;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  -webkit-box-shadow: 6px 7px 37px -12px rgba(72, 72, 72, 1);
  box-shadow: 6px 7px 37px -12px rgba(72, 72, 72, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 70;
`;

const Emoji = styled.div`
  position: absolute;
  font-size: 100px;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  color: #ffffff22;
  overflow: hidden;
  font-weight: 800;
`;
const Mensaje = styled.div``;

const ErrorMsj = ({ text }) => {
  return (
    <Contenedor>
      <Mensaje>{text}</Mensaje>
      <Emoji>;( </Emoji>
    </Contenedor>
  );
};

export default ErrorMsj;
