import React from 'react';
import styled from 'styled-components';

import NavBar from '../components/navbar/NavBarV1';
import ChartV2 from '../components/home/ChartV2';
import Controles from '../components/home/Controles';
import Estadisticas from '../components/home/Estadisticas';

import SeriesContextProvider from '../context/SeriesContext';
import Tarjeta from '../components/home/Tarjeta';

const Contenedor = styled.div`
  /* display: flex; */
  width: 100%;
  min-height: 965px;
  /* flex-wrap: wrap; */
  /* background-color: pink; */
  background-color: #f3f1f5;
`;

const Center = styled.div`
  width: 1200px;
  /* height: 800px; */
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  column-gap: 40px;
  padding-top: 110px;
  flex-direction: column;
`;

const WrapperPaneles = styled.div`
  display: flex;
  /* background-color: pink; */
  justify-content: space-between;
`;

const Panel = styled.div`
  /* width: 30%; */
  /* height: 100%; */
  /* background-color: maroon; */
  display: flex;
  justify-content: center;
`;

const Pie = styled.div`
  width: 100%;
  height: 200px;
  /* background-color: greenyellow; */
  margin-top: 30px;
`;
const Separador = styled.div`
  width: 100%;
  height: 52px;
`;

const Home = () => {
  return (
    <Contenedor>
      <NavBar></NavBar>
      <Center>
        <SeriesContextProvider>
          <WrapperPaneles>
            <Panel
              style={{
                width: '300px',
                // position: 'relative',
                // paddingRight: '70px',
                // backgroundColor: 'orangered',
                // flexGrow: '1',
                // flex: '0 1 auto',
                // columnGap: '70px',
              }}
            >
              <Tarjeta tit="Herramientas" ico={'#icon-tools'}>
                <Separador></Separador>
                <Controles calendario={true}></Controles>
              </Tarjeta>
            </Panel>
            <Panel
              style={
                {
                  // padding: '70px',
                  // position: 'relative',
                  // paddingRight: '70px',
                  // backgroundColor: 'lightgreen',
                  // flexGrow: '3',
                  // columnGap: '70px',
                }
              }
            >
              <ChartV2 ancho={800}></ChartV2>
            </Panel>
          </WrapperPaneles>
          <Pie>
            <Tarjeta tit="Estadística" ico={'#icon-line-graph'}>
              <Estadisticas></Estadisticas>
            </Tarjeta>
          </Pie>
        </SeriesContextProvider>
      </Center>
    </Contenedor>
  );
};

export default Home;
