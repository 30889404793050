import React from 'react';
import styled from 'styled-components';
import TextLoop from 'react-text-loop';

// import fondo2 from '../res/imgs/fondo2.jpg';
import LoginForm from '../../components/login/LoginForm';

const Contenedor = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const LoginWrapper = styled.div`
  display: flex;
  position: relative;
  /* right: 40px; */
  /* bottom: -400px; */
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  /* margin-top: 23px; */
  color: #e02401;
  /* font-family: 'Archivo Black', sans-serif; */
  font-family: 'Mitr', sans-serif;
  /* font-family: 'Suez One', serif; */
  font-size: 70px;
  font-weight: 500;
  letter-spacing: 3px;
`;

const TittuloWrapper = styled.div`
  display: inline-block;
`;

const Titulo = styled.h1`
  font-size: 17px;
  /* position: absolute;
  bottom: 10px; */
  /* margin-left: 60px; */
  /* left: 70px; */
  /* margin-top: 150px; */
  /* top: 100px; */
  font-weight: 100;
  width: 250px;
  font-family: 'Montserrat', sans-serif;
  /* line-height: 110px; */
  letter-spacing: 5px;
  /* position: absolute; */
  /* margin-bottom: 60px; */
`;

const Elemento = styled.span`
  font-weight: 200;
  font-size: 20px;
`;

const LandingMobile = () => {
  console.log('made with ❤️ by RC');

  return (
    <>
      <Contenedor>
        <Logo>bamed</Logo>
        {/* <Imagen src={fondo2} style={{ left: '0px' }} />
        <Esquema src={esquema} /> */}

        <LoginWrapper
          style={{
            // padding: '70px',
            // position: 'relative',
            // paddingRight: '70px',
            // backgroundColor: 'orangered',
            justifyContent: 'flex-end',
            // columnGap: '70px',
          }}
        >
          <LoginForm></LoginForm>
        </LoginWrapper>
        <TittuloWrapper>
          <Titulo>
            especialistas en
            <TextLoop
              style={{ fontWeight: '400' }}
              //   interval={2000}
              //   fade={false}
              //   springConfig={{ stiffness: 180, damping: 8 }}
              //   children={['mediciones', 'monitoreo', 'control', 'bioingeniería']}
            >
              <Elemento>mediciones</Elemento>
              <Elemento>monitoreo</Elemento>
              <Elemento>control</Elemento>
              <Elemento>bioingeniería</Elemento>
              <Elemento>telemetría</Elemento>
            </TextLoop>
          </Titulo>
        </TittuloWrapper>
      </Contenedor>
    </>
  );
};

export default LandingMobile;
