import React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import useAuth from '../auth/useAuth';

// const user = null;
// const user = { id: 1, username: 'hola' };
// el objetivo es conesguir o un usuario null -> no autenticado
// o un usuario {id:.., username:..}

const PrivateRoute = ({ component: Component, ...rest }) => {
  //hay que darle un alias a component en mayuscula para que react lo tome como un componente

  const auth = useAuth();
  const location = useLocation();

  return (
    <Route {...rest}>
      {auth.isLogged() ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )}
    </Route>
    // desestructuramos props ({...props}). es equivalente a
    // <Route exact={props.exact} path={props.path} component={props.component} />
  );
};

export default PrivateRoute;
