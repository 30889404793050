import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';

import useCheckMobileScreen from '../hooks/useCheckMobileScreen';
import HomePage from '../pages/Home';
import HomeMobile from '../pages/mobile/HomeMobile';
import LandingMobile from '../pages/mobile/LandingMobile';
// import Landingx from '../pages/Landingx';
import NotFoundPage from '../pages/NotFoundPage';
import QRPageMobile from '../pages/mobile/QRPageMobile';
// import ConfirmMail from '../pages/ConfirmMail';
// import ForgotPasswordPage from '../pages/ForgotPasswordPage';
// import ResetPasswordPage from '../pages/ResetPasswordPage';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const AppRouter = () => {
  const isMobile = useCheckMobileScreen();
  // const [width, setWidth] = useState(window.innerWidth);
  // console.log(isMobile);
  return (
    <Router>
      <Switch>
        {/* <PublicRoute exact path="/login" component={Landingx} /> */}
        <PublicRoute exact path="/qr/:qrcode" component={QRPageMobile} />
        <PublicRoute exact path="/login" component={LandingMobile} />

        {/* <PublicRoute exact path="/register" component={RegisterPage} /> */}
        {/* <PublicRoute
          exact
          path="/forgotpassword"
          component={ForgotPasswordPage}
        /> */}
        {/* <PublicRoute
          exact
          path="/resetpassword/:resetToken"
          component={ResetPasswordPage}
        /> */}

        <PrivateRoute
          exact
          path="/"
          component={isMobile ? HomeMobile : HomePage}
        />

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
