import { createGlobalStyle } from 'styled-components';
import React from 'react';

import GlobalFonts from './res/fonts/fonts';
// import ChartV2 from './components/ChartV2';

// import Landingx from './pages/Landingx';
import AuthProvider from './auth/AuthProvider';
import AppRouter from './routers/AppRouter';
// import Home from './pages/Home';
// import Logo from './components/Logo';

// import Chart from '../components/Chart';

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  overflow-x:hidden;
  /* overflow-y:hidden; */
  /* overflow:auto; */
  background-color: #f3f1f5;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
 
  font-weight: 400;

  /* background-color:#f3f3f3; */
/* letter-spacing: -25px; */

// Desactivar la seleccion de texto
  /* -webkit-touch-callout: none; iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
`;

const Appx = () => {
  return (
    <>
      <GlobalStyle />
      <GlobalFonts />

      <AuthProvider>
        <AppRouter />
      </AuthProvider>

      {/* <Logo></Logo> */}
      {/* <Landingx></Landingx> */}

      {/* <Home></Home> */}
    </>
  );
};

export default Appx;
