import React from 'react';
import styled from 'styled-components';

const Contenedor = styled.div`
  /* height: 100%; */
  /* width: auto; */
  text-align: ${(props) => props.centrado || 'center'};
  background-color: ${(props) => props.colorFondo || '#f6f6f6'};
  display: block;

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  margin: 0;
  padding: 5px;
  border-right: 1px solid #88888833;
  /* border-left: 1px solid #88888833; */
  font-weight: ${(props) => props.pesoFuente || 500};
`;

const Elemento = styled.p`
  display: block;
  margin: 0;
  padding: 0;

  font-size: 14px;
  white-space: nowrap;

  text-shadow: 2px 2px 12px #88888822;
`;

const ElemEstadistica = ({
  prim,
  seg,
  ter,
  cuart,
  quin,
  sext,
  color,
  centrado,
  peso,
}) => {
  return (
    <Contenedor colorFondo={color} centrado={centrado} pesoFuente={peso}>
      <Elemento>{prim}</Elemento>
      <Elemento>{seg}</Elemento>
      <Elemento>{ter}</Elemento>
      <Elemento>{cuart}</Elemento>
      <Elemento>{quin}</Elemento>
      <Elemento>{sext}</Elemento>
    </Contenedor>
  );
};

export default ElemEstadistica;
