export const unidades = [
  {
    tipo: 't',
    unidad: '°C',
    nombre: 'temperatura',
    max: 150,
    min: -50,
  },
  {
    tipo: 'h',
    unidad: '%',
    nombre: 'humedad',
    max: 100,
    min: 0,
  },
];
