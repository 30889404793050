export const pie_new_post = {
  foto: 'Foto',
  video: 'Video',
  evento: 'Evento',
  escribir_articulo: 'Escribir Artículo',
  curso: 'Curso',
};

export const pie_post = {
  reaccionar: 'Reaccionar',
  comentar: 'Comentar',
  compartir: 'Compartir',
  enviar: 'Enviar',
};

export const titulo_menu = {
  notificaciones: 'Notificaciones',
  inversiones: 'Inversiones',
  articulos: 'Artículos',
  eventos: 'Eventos',
  cursos: 'Cursos',
};

export const pie_menu = {
  notificaciones: 'ver todas',
  inversiones: 'ver todas las inversiones',
  articulos: 'ver todos los artículos',
  eventos: 'ver todos los eventos',
  cursos: 'ver todos los cursos',
};

export const login_form = {
  email: 'correo electrónico',
  recup: 'olvidaste tu contraseña?',
  pwd: 'contraseña',
  ingresar: 'ingresar',
  crear_cuenta: 'crear una cuenta',
};

export const register_form = {
  email: 'Correo Electrónico',
  email_ph: 'Ingrese su Correo Electrónico',
  nombre: 'Nombre',
  nombre_ph: 'Ingrese su Nombre',
  apellido: 'Apellido',
  apellido_ph: 'Ingrese su Apellido',
  nombre_usuario: 'Nombre de Usuario',
  nombre_usuario_ph: 'Ingrese su Nombre de Usuario',
  password: 'Contraseña',
  password_ph: 'Ingrese su Contraseña',
  password_confirm: 'Confirme la Contraseña',
  password_confirm_ph: 'Confirme su Contraseña',
  titulo: 'Bienvenido a bamed! ',
  subtitulo: 'Ingrese a su tablero de mediciones',
  boton: 'Acceder',
  pie: '¿Ya tienes una cuenta? ',
  link: 'Haz click aquí ',
  pie_login: '¿No tienes una cuenta?',
  forgot: '¿Olvidaste tu contraseña?',
};

export const forgotp_form = {
  email: 'Correo Electrónico',
  email_ph: 'Ingrese su Correo Electrónico',
  titulo: 'Recupera tu cuenta ',
  subtitulo: 'Ingresa tu correo electrónico para buscar tu cuenta.',
  boton: 'Recuperar',
  pie: '¿Ya tienes una cuenta? ',
  link: 'Haz click aquí ',
};
