export const login = {
  sombra: '7px 7px 16px 0 rgb(128 128 128 / 30%);',
  color: '#023047',
  color_boton: '#023047',
};

export const fuentes = {
  primaria: `'Roboto', sans-serif`,
  secundaria: `'Montserrat', sans-serif`,
};

export const tarjeta = {
  sombra: ' 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
  radio: 8,
  color_fondo: '#FFFFFF',
  padding: '12px',
  alto_sticky: '70px',
};

export const navbar = {
  sombra: ' 0px 0px 0px 1px rgba(0, 0, 0, 0.06)',
  radio: '0.8rem',
  color_fondo: '#FFFFFF',
  altura: 52,
};

export const general = {
  color_fondo1: '#F3F3F7',
  color_fondo2: '#dfe7fd66',
  color_fondo: '#f0ddbc44',
};

export const color = {
  seleccion: '#eaf4f4',
  iconos: '#777777ee;',
  notificacion: '#eb2f64',
  salir: '#d11124',
  icono_oscuro: '#22223b',
};

export const color_formulario = {
  fondo: '#fbfbfb',
  letra_activa: 'rgba(80, 80, 80, 1)',
  letra_inactiva: '#c0c0c0',
  boton_solido: ' #eb2c6a',
};

/* -------------------------------- EJEMPLOS -------------------------------- */
// PANEL -> https://demos.creative-tim.com/material-dashboard/examples/tables.html
// LOGIN -> https://login.yahoo.com/?.src=ym&.lang=es-ES&.intl=es&.done=https%3A%2F%2Fmail.yahoo.com%2Fd
// DETECTAR CLICK POR FUERA -> https://www.cluemediator.com/detect-click-outside-a-react-component-using-react-hooks
//https://html-css-js.com/html/character-codes/arrows/
