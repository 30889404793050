import React from 'react';
import styled from 'styled-components';

const Contenedor = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  /* background-color: limegreen; */
  /* margin-top: 30px; */
  margin: 5px 0;
`;

const Titulo = styled.p`
  margin-left: 10px;
  padding: 0 5px;
  display: inline-block;
  font-size: 13px;
  margin-right: 7px;
`;

const Linea = styled.div`
  background-color: #e0240155;
  margin-left: auto;
  height: 1px;
  width: 100%;
  margin-right: 15px;
`;

const Separador = ({ titulo }) => {
  return (
    <Contenedor>
      <Titulo>{titulo}</Titulo>
      <Linea></Linea>
    </Contenedor>
  );
};

export default Separador;
