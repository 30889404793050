import { createGlobalStyle } from 'styled-components';

import vestebroPosterWoff2 from './Vesterbro-Poster.woff2';
import MonumentExtendedRegularWoff2 from './MonumentExtended-Regular.woff2';
import MonumentExtendedLightWoff2 from './MonumentExtended-Light.woff2';
import MonumentExtendedBlackWoff2 from './MonumentExtended-Black.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'vestebro';
        src: local('vestebro'),
        url(${vestebroPosterWoff2}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'monument';
        src: local('monument'),
        url(${MonumentExtendedRegularWoff2}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'monument';
        src: local('monument'),
        url(${MonumentExtendedLightWoff2}) format('woff2');
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: 'monument';
        src: local('monument'),
        url(${MonumentExtendedBlackWoff2}) format('woff2');
        font-weight: 800;
        font-style: normal;
    }
`;
