import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

// import { dummy } from './objPrueba';
import { useSeriesAndStats } from '../../context/SeriesContext';
// import useCheckMobileLandscapeMode from '../../hooks/useCheckMobileLandscapeMode';
import Spinner from '../loader/Spinner';

const Contenedor = styled.div`
  /* box-shadow: rgba(149, 157, 165, 0.75) 0px 8px 24px; */
  width: 100%;
  /* height: 938px; */
  /* margin: 20px; */
  /* font-family: 'Montserrat', sans-serif; */
  /* padding: 30px; */
  /* display: flex; // ajusta el div al contenido */
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: #ffffff; */
  /* border-radius: 8px; */
  /* position: relative; */
`;

const Loader = styled.div`
  position: relative;
`;

const Grafico = () => {
  const { series, loading, unidad } = useSeriesAndStats();
  const [vmin, setVmin] = useState();
  const [vmax, setVmax] = useState();
  const [leyendaY, setLeyendaY] = useState(
    unidad ? `${unidad.nombre} [ ${unidad.unidad} ]` : ''
  );
  const [titulo, setTitulo] = useState(unidad ? `${unidad.nombre}` : '');

  // const [pantallaHoriz, setPantallaHoriz] = useState(
  //   window.innerWidth > window.innerHeight
  // );
  // const renderCount = useRef(0); // el detector de landscape presenta el problema de que el primer render es erroneo y despues funciona bien
  // useref crea variables que persisten entre renders
  // const isLandscape = useCheckMobileLandscapeMode();

  // useEffect(() => {
  //   renderCount.current = renderCount.current + 1;
  //   console.log(renderCount.current);
  //   console.log(isLandscape);
  // });
  // const [rotado, setRotado] = useState(isLandscape);

  // console.log('landscape', isLandscape);

  // console.log('rotado', rotado);
  // console.log(window.height < window.width);
  useEffect(() => {
    // console.log(unidad);
    if (unidad) {
      setLeyendaY(`${unidad.nombre} [ ${unidad.unidad} ]`);
      setTitulo(`${unidad.nombre}`);
    }
    if (series) {
      // console.log(series);
      let auxVmin = series[0].stats[0].minValue;
      let auxVmax = series[0].stats[0].maxValue;

      series.forEach((element) => {
        if (element.stats[0].minValue < auxVmin) {
          auxVmin = element.stats[0].minValue;
        }
        if (element.stats[0].maxValue > auxVmax) {
          auxVmax = element.stats[0].maxValue;
        }
      });

      setVmin(
        Math.round(
          auxVmin >= 0 ? auxVmin - auxVmin * 0.1 : auxVmin + auxVmin * 0.1
        )
      );
      setVmax(
        Math.round(
          auxVmax >= 0 ? auxVmax + auxVmax * 0.1 : auxVmax - auxVmax * 0.1
        )
      );

      // setVmin(Math.round(auxVmin - auxVmin * 0.1));
      // setVmax(Math.round(auxVmax + auxVmax * 0.1));
    }
    // setRotado(isLandscape);
  }, [series]);

  // useEffect(() => {

  // }, [isLandscape]);

  let options = {
    chart: {
      // width: '200px', //!isLandscape ? '220%' : '120%',
      id: 'area-datetime',
      // type: 'area',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.1,
      },
      // height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: true,
          // reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
      },
    },

    xaxis: {
      type: 'datetime',
      // min: new Date('01 Mar 2012').getTime(),
      tickAmount: 12,
      title: {
        text: 'Hora',
      },
      labels: {
        datetimeUTC: false,
      },
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },

    yaxis: {
      title: {
        text: leyendaY,
      },
      tickAmount: 10,
      min: vmin,
      max: vmax,
    },
    tooltip: {
      x: {
        format: 'HH:mm - dd/MM/yy',
      },
    },
    stroke: {
      curve: 'smooth',
      width: 3.5,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      floating: false,
      offsetY: -1,
      //   offsetX: -5,
      //   height: 3,
    },
    noData: {
      text: 'No hay datos...',
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shadeIntensity: 1,
    //     opacityFrom: 0.4,
    //     opacityTo: 0.03,
    //     inverseColors: false,
    //     // stops: [0, 30],
    //   },
    // },

    dataLabels: {
      enabled: false,
    },
    title: {
      text: titulo,
    },
  };

  return (
    <Contenedor>
      {!loading && series ? (
        <Chart
          options={options}
          series={series}
          type={'line'}
          width={'98%'}
          // width={
          //   (
          //     renderCount.current < 5
          //       ? window.innerHeight < window.innerWidth
          //       : !isLandscape
          //   )
          //     ? '220%'
          //     : '120%'
          // }
          height={600}
        ></Chart>
      ) : (
        series && (
          <Loader>
            <Spinner></Spinner>
          </Loader>
        )
      )}
    </Contenedor>
  );
};
export default Grafico;
