import { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import axiosInstance from '../config/axios';

// contexto. equivalente a variables globales que pueden ser accedidas por cualquier componente de la app
// simepre y cuando esos componentes esten encerrados dentro del componente authprovider (AuthContext.Provider)
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem('user')) || null //puede ser ek token
  // );

  //guardo en el local storage las credenciales por si se recarga la pagina
  // useEffect(() => {
  //   try {
  //     localStorage.setItem('user', JSON.stringify(user));
  //   } catch (error) {
  //     localStorage.removeItem('user');
  //     console.log(error);
  //   }
  // }, [user]);

  useEffect(() => {
    // si hay una cookie jwt enviarla a la api
    // obtener el usuario asociado
    // cargar usuario en state
    const jwt = Cookies.get('jwt');
    /* ------------------------------------ A ----------------------------------- */
    /* ----------- PENDIENTE: LEER COOKIE Y GUARDARLA EN NUEVO DOMINIO ---------- */

    // const jwt = Cookies.get('jwt', { domain: 'biotherm.herokuapp.com' });
    // console.log(jwt); //<---------------------- ACA quede
    // Cookies.set('jwt', jwt, { path: '/' });
    /* -------------------------------------------------------------------------- */
    // console.log('cookie--->>>>', jwt);
    if (jwt && typeof jwt !== 'undefined') {
      try {
        // console.log('ENTROOOO', jwt);
        async function obtenerUsuario() {
          const { data } = await axiosInstance.get('/users/token', {});
          // setUser(data.data.user);
          setUser({ ...data.data.user, token: jwt });
          // console.log(data.data.user);
        }
        obtenerUsuario();
      } catch (error) {
        console.log('JWT INVALIDA', error);
      }
    } else {
      console.log('JWT NO ENCONTRADO');
    }
  }, []);

  const contextValue = {
    user,
    login(usuario) {
      setUser(usuario);
    },
    logout() {
      setUser(null);
      Cookies.remove('jwt', { path: '/' });
    },
    isLogged() {
      return !!user;
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
