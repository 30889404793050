import React from 'react';
import styled from 'styled-components';

import NavBarMobile from '../../components/navbar/NavBarMobile';
import Grafico from '../../components/home/Grafico';
import Controles from '../../components/home/Controles';
import Separador from '../../components/home/Separador';

import SeriesContextProvider from '../../context/SeriesContext';
import EstadisticasMobile from '../../components/home/EstadisticasMobile';

const Contenedor = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 100vh; */
  position: relative;
  overflow-x: hidden;
`;
const Espacio = styled.div`
  width: 100%;
  height: 52px;
`;

const HomeMobile = () => {
  return (
    <Contenedor>
      <NavBarMobile></NavBarMobile>
      <SeriesContextProvider>
        <Espacio></Espacio>
        {/* <h1>{window.innerWidth}</h1> */}
        <Separador titulo={'Herramientas'}></Separador>
        <Controles calendario={true}></Controles>
        <Separador titulo={'Gráfico'}></Separador>
        <Grafico></Grafico>
        <Separador titulo={'Estadísticas'}></Separador>
        <EstadisticasMobile></EstadisticasMobile>
        {/* <Center>
        <SeriesContextProvider>
          <WrapperPaneles>
            <Panel
              style={{
                width: '300px',
                // position: 'relative',
                // paddingRight: '70px',
                // backgroundColor: 'orangered',
                // flexGrow: '1',
                // flex: '0 1 auto',
                // columnGap: '70px',
              }}
            >
             
            </Panel>
            <Panel
              style={
                {
                  // padding: '70px',
                  // position: 'relative',
                  // paddingRight: '70px',
                  // backgroundColor: 'lightgreen',
                  // flexGrow: '3',
                  // columnGap: '70px',
                }
              }
            >
              <ChartV2 ancho={800}></ChartV2>
            </Panel>
          </WrapperPaneles>
          <Pie>
            <Estadisticas></Estadisticas>
          </Pie>
        </SeriesContextProvider>
      </Center> */}
      </SeriesContextProvider>
    </Contenedor>
  );
};

export default HomeMobile;
