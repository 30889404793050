import React from 'react';
import styled from 'styled-components';

// import NavBarMobile from '../../components/navbar/NavBarMobile';
import Grafico from '../../components/home/Grafico';
// import Controles from '../../components/home/Controles';
import Separador from '../../components/home/Separador';

import SeriesContextProvider from '../../context/SeriesContext';
import EstadisticasMobile from '../../components/home/EstadisticasMobile';
import ControlesMobileQR from '../../components/home/ControlesMobileQR';
import spriteNotif from '../../res/svgs/notif.svg';

const Contenedor = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 100vh; */
  position: relative;
  overflow-x: hidden;
`;
// const Espacio = styled.div`
//   width: 100%;
//   height: 52px;
// `;

const IconoWrapper = styled.div`
  /* background-color: green; */
  margin-top: 20px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
`;

const IconoTexto = styled.div`
  width: 90px;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
`;

const Icono = styled.svg`
  /* background-color: limegreen; */
  height: 70px;
  width: 70px;
  margin-right: 20px;
  transition: all 0.35s;
  /* padding: 30px; */
  /* fill: black; */
  fill: #e02401; //#555555;
  /* filter: drop-shadow(2px 2px 4px #152d3533); */
  /* box-shadow: 0 16px 32px #152d35; */
  /* background-color: transparent; */
`;

// const Logo = styled.div`
//   /* margin-top: 23px; */
//   /* margin-left: 40px; */
//   color: #e0240133;
//   /* font-family: 'Archivo Black', sans-serif; */
//   font-family: 'Mitr', sans-serif;
//   margin-right: auto;
//   /* font-family: 'Suez One', serif; */
//   font-size: 45px;
//   font-weight: 500;
//   letter-spacing: 3px;
//   z-index: 91;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

// const Texto = styled.div`
//   padding: 10px 25px;
//   font-size: 13px;
//   font-weight: 300;
//   /* margin-top: 52px; */
//   /* margin-bottom: 16px; */
// `;

const QRPageMobile = () => {
  return (
    <Contenedor>
      {/* <NavBarMobile></NavBarMobile> */}
      <IconoWrapper>
        <Icono>
          <use href={spriteNotif + '#icon-qr_code'} />
        </Icono>
        <IconoTexto>lecturas de las últimas 24hs</IconoTexto>
      </IconoWrapper>
      <SeriesContextProvider>
        {/* <Espacio></Espacio> */}
        {/* <h1>{window.innerWidth}</h1> */}
        <Separador titulo={'Herramientas'}></Separador>
        <ControlesMobileQR></ControlesMobileQR>
        <Separador titulo={'Gráfico'}></Separador>
        <Grafico></Grafico>
        <Separador titulo={'Estadísticas'}></Separador>
        <EstadisticasMobile></EstadisticasMobile>
        {/* <Center>
        <SeriesContextProvider>
          <WrapperPaneles>
            <Panel
              style={{
                width: '300px',
                // position: 'relative',
                // paddingRight: '70px',
                // backgroundColor: 'orangered',
                // flexGrow: '1',
                // flex: '0 1 auto',
                // columnGap: '70px',
              }}
            >
             
            </Panel>
            <Panel
              style={
                {
                  // padding: '70px',
                  // position: 'relative',
                  // paddingRight: '70px',
                  // backgroundColor: 'lightgreen',
                  // flexGrow: '3',
                  // columnGap: '70px',
                }
              }
            >
              <ChartV2 ancho={800}></ChartV2>
            </Panel>
          </WrapperPaneles>
          <Pie>
            <Estadisticas></Estadisticas>
          </Pie>
        </SeriesContextProvider>
      </Center> */}
      </SeriesContextProvider>
    </Contenedor>
  );
};

export default QRPageMobile;
