import React from 'react';
import styled from 'styled-components';

import { navbar, color } from '../../config/theme/variables';
import sprite from '../../res/svgs/symbol-defs.svg';
import useAuth from '../../auth/useAuth';

const Contenedor = styled.div`
  width: 100%;
  height: 52px;
  background-color: ${navbar.color_fondo};
  box-shadow: ${navbar.sombra};
  position: fixed;
  z-index: 90;
  top: 0px;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
`;
const Logo = styled.div`
  /* margin-top: 23px; */
  margin-left: 10px;
  color: #e02401;
  /* font-family: 'Archivo Black', sans-serif; */
  font-family: 'Mitr', sans-serif;
  margin-right: auto;
  /* font-family: 'Suez One', serif; */
  font-size: 37px;
  font-weight: 500;
  letter-spacing: 3px;
  z-index: 91;
`;

const Botones = styled.div`
  height: 90%;
  margin-right: 20px;
  /* width: 100px; */

  /* margin-right: calc((100vw - 1200px) / 2); */
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Icono = styled.svg`
  width: 23px;
  height: 23px;
  fill: ${(props) => props.color};
  position: relative;
  font-weight: 300;
  margin: 0 5px;
  transition: all 0.35s;
`;
const Texto = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-10px);
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  color: ${(props) => props.color};
  transition: all 0.35s;
  font-weight: 400;
`;

const Wrapper = styled.label``;
const Boton = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  height: 48px;
  padding: 0;
  border-radius: 32px;
  transition: all 0.35s;

  &:hover {
    cursor: pointer;
    & ${Texto} {
      visibility: visible;
      opacity: 1;
      transform: translateY(-50%) translateX(1px);
    }
    & ${Icono} {
      transform: translateX(-20px);
    }
  }
  &:active {
    transform: scale(0.9);
  }
`;

const NavBarMobile = () => {
  const auth = useAuth();

  const handleOnClickSalir = (event) => {
    auth.logout();
  };
  return (
    <Contenedor>
      <Logo>bamed</Logo>
      <Botones>
        {/* <Wrapper>
            <Boton onClick={handleOnClickHome}>
              <Icono color={color.iconos}>
                <use href={sprite + '#icon-home1'} />
              </Icono>
              <Texto color={color.iconos}>Inicio</Texto>
            </Boton>
          </Wrapper>
          <Wrapper>
            <Boton onClick={handleOnClickHome}>
              <Icono color={color.iconos}>
                <use href={sprite + '#icon-earth'} />
              </Icono>
              <Texto color={color.iconos}>Contactos</Texto>
            </Boton>
          </Wrapper>
          <Wrapper>
            <Boton onClick={handleOnClickHome}>
              <Icono color={color.iconos}>
                <use href={sprite + '#icon-briefcase1'} />
              </Icono>
              <Texto color={color.iconos}>Cartera</Texto>
            </Boton>
          </Wrapper>
          <Wrapper>
            <Boton onClick={handleOnClickHome}>
              <Icono color={color.iconos}>
                <use href={sprite + '#icon-bubbles'} />
              </Icono>
              <Texto color={color.iconos}>Mensajes</Texto>
            </Boton>
          </Wrapper>
          <Wrapper>
            <Boton onClick={handleOnClickHome}>
              <Icono color={color.iconos}>
                <use href={sprite + '#icon-bookmarks'} />
              </Icono>
              <Texto color={color.iconos}>Avisos</Texto>
            </Boton>
          </Wrapper> */}
        <Wrapper>
          <Boton onClick={handleOnClickSalir}>
            <Icono color={color.salir}>
              <use href={sprite + '#icon-log-out'} />
            </Icono>
            <Texto color={color.salir}>Salir</Texto>
          </Boton>
        </Wrapper>
      </Botones>
    </Contenedor>
  );
};

export default NavBarMobile;
