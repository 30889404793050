import React from 'react';
import styled from 'styled-components';

import ElemEstadistica from './ElemEstadistica';

import { useSeriesAndStats } from '../../context/SeriesContext';

const Contenedor = styled.div`
  /* box-shadow: rgba(149, 157, 165, 0.75) 0px 8px 24px; */
  width: 90%;
  /* height: 300px; */
  /* background-color: limegreen; */
  /* margin: 20px; */
  font-family: 'Montserrat', sans-serif;
  /* padding: 10px; */
  display: flex; // ajusta el div al contenido
  /* background-color: #ffffff; */
  /* border-radius: 8px; */
  position: relative;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const EstadisticasWrapper = styled.div`
  /* background-color: pink; */
  max-width: 96%;
  height: 80%;
  margin-bottom: 13px;
  display: flex;
  justify-content: flex-start;
  /* overflow-x: scroll; */
`;
const EstadisticasElementosWrapper = styled.div`
  /* background-color: lightgreen; */
  width: 80%;
  height: 100%;
  margin-bottom: 13px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 1;
`;

const EstadisticasMobile = () => {
  const { series, loading } = useSeriesAndStats();
  return (
    <Contenedor>
      {series && !loading && (
        <EstadisticasWrapper>
          <ElemEstadistica
            color="transparent"
            centrado="right"
            prim="Ubicación [id]"
            seg="Nivel de Alarma"
            ter="Valor Max"
            cuart="Valor Med"
            quin="Valor Min"
            sext="N° de Muestras"
            peso={600}
          ></ElemEstadistica>
          <EstadisticasElementosWrapper>
            {series.map((serie, index) => (
              <ElemEstadistica
                // El + convierte en string al nro
                key={serie.stats[0]._id}
                prim={`${serie.ubicacion} [${serie.stats[0]._id}]`}
                seg={+serie.alert.toFixed(2)}
                ter={+serie.stats[0].maxValue.toFixed(2)}
                cuart={+serie.stats[0].avgValue.toFixed(2)}
                quin={+serie.stats[0].minValue.toFixed(2)}
                sext={+serie.stats[0].numSample.toFixed(2)}
                color={index % 2 === 0 ? '#ececec55' : 'transparent'}
              ></ElemEstadistica>
            ))}
          </EstadisticasElementosWrapper>
        </EstadisticasWrapper>
      )}
    </Contenedor>
  );
};

export default EstadisticasMobile;
