import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import axiosInstance from '../../config/axios';
import { useSeriesAndStats } from '../../context/SeriesContext';

import ErrorMsj from '../error/ErrorMsj';
import useAuth from '../../auth/useAuth';
import { unidades } from '../../config/equivalencias';
// import spriteNotif from '../../res/svgs/notif.svg';
import { useParams } from 'react-router';
// import { map } from '../../../../temp-dl-be/app';

const Contenedor = styled.div`
  /* box-shadow: rgba(149, 157, 165, 0.75) 0px 8px 24px; */
  /* width: 100%; */
  /* height: 100%; */
  /* margin: 20px; */
  /* font-family: 'Montserrat', sans-serif; */
  /* padding: 30px; */
  /* display: inline-block; // ajusta el div al contenido */
  /* background-color: #ffffff; */
  /* border-radius: 8px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  margin-bottom: 30px;
`;

const Texto = styled.div`
  padding: 10px 25px;
  font-size: 13px;
  font-weight: 300;
  /* margin-top: 52px; */
  /* margin-bottom: 16px; */
`;

const BotonWrapper = styled.div`
  margin-top: 30px;
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: pink; */
  /* height: 100px; */
`;

const Boton = styled.button`
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #152d35;
  border: none;
  border-radius: 5px;
  padding: 25px 15px;
  /* width: 95px; */
  height: 40px;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  /* margin: 10px 20px 20px 5px; */
  font-size: 16px;
  transition: all 0.2s;
  outline: none;
  margin-bottom: 20px;
  &:hover:not(:disabled) {
    transform: translateY(-3px);
    cursor: pointer;
    background-color: #eb2c6a;
    box-shadow: 0 16px 32px #152d3522;
  }
  &:focus {
    transform: translateY(-1px);
    cursor: pointer;
    box-shadow: 0 8px 16px #152d3522;
  }
  &:active {
    transform: translateY(-1px);
    cursor: pointer;
    box-shadow: 0 8px 16px #152d3522;
  }
  &:visited {
    transform: translateY(-1px);
    cursor: pointer;
    box-shadow: 0 8px 16px #152d3522;
  }
  &:disabled {
    background-color: gray;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 10px;
`;

const SeleccionWrapper = styled.div`
  /* background-color: limegreen; */
  width: 95%;
`;

const pulse = keyframes`
    0%{
      opacity: 0;
      transform: scale(0.5);
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 0;
      transform: scale(1.75);
    }
   
  `;

const Selecciones = styled.div`
  padding: 10px 40px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 250px;
`;

const Etiqueta = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const RadioBoton = styled.span`
  position: relative;
  z-index: 1;
  display: grid;
  place-items: center;
  width: 7px;
  height: 7px;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid #9d9d9d55;
`;
const RadioBotonInterno = styled.span`
  display: block;
  position: relative;
  z-index: 2;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: black;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: all 0.35s;
`;
const RadioPulso = styled.span`
  position: absolute;
  display: none;
  top: 1px;
  left: -2px;
  z-index: 0;
  opacity: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #00000033;
  animation: ${pulse} 0.5s linear;
`;

const RadioEtiqueta = styled.span`
  font-size: 1.25rem;
  color: #9d9d9d55;
`;

const Radio = styled.input`
  position: absolute;
  transform: scale(0);

  &:checked ~ ${RadioBoton} ${RadioBotonInterno} {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  &:checked ~ ${RadioPulso} {
    display: block;
  }
  &:checked ~ ${RadioBoton} {
    border-color: black;
  }
  &:checked ~ ${RadioEtiqueta} {
    color: #9d9d9dff;
  }
`;

// const HerramientasWrapper = styled.div`
//   /* background-color: pink; */
//   width: 95%;
//   margin-top: 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const IconoWrapper = styled.div`
//   /* background-color: green; */
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// const IconoTexto = styled.div`
//   width: 70px;
//   font-size: 12px;
//   text-align: center;
//   font-weight: 500;
// `;

// const Icono = styled.svg`
//   /* background-color: limegreen; */
//   height: 70px;
//   width: 70px;
//   transition: all 0.35s;
//   /* padding: 30px; */
//   fill: #e02401; //#555555;
//   /* filter: drop-shadow(2px 2px 4px #152d3533); */
//   /* box-shadow: 0 16px 32px #152d35; */
//   /* background-color: transparent; */
//   &:hover {
//     transform: scale(1.1);
//     /* filter: drop-shadow(2px 2px 12px #152d3533); */
//     cursor: pointer;
//   }
//   &:active {
//     transform: translateY(-1px);
//     cursor: pointer;
//     /* box-shadow: 0 8px 16px #152d3522; */
//   }
// `;

const ContenedorUbicaciones = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
`;
const OpcionCheckBox = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  margin-bottom: 5px;
  gap: 10px;
`;

// https://dmitripavlutin.com/react-context-and-usecontext/
const ControlesMobileQR = () => {
  const [bugs, setBugs] = useState();

  const [error, setError] = useState('');
  const variables = useRef([]);
  const [opcionTipo, setOpcionTipo] = useState('t');
  const [selectedBugs, setSelectedBugs] = useState([]);
  const { qrcode } = useParams();

  const auth = useAuth();

  const { setSeries, setLoading, loading, setUnidad } = useSeriesAndStats();

  const onChangeRadio = (event) => {
    setOpcionTipo(event.target.value);
    setUnidad(unidades.find((x) => x.tipo === event.target.value));

    // setSelectedBugs([]);
  };

  const onChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    let valoresBugs = [...selectedBugs];
    if (checked) {
      if (!valoresBugs.includes(name)) {
        valoresBugs.push(name);
        setSelectedBugs(valoresBugs);
      }
    } else {
      if (valoresBugs.includes(name)) {
        let index = valoresBugs.indexOf(name);
        if (index !== -1) {
          valoresBugs.splice(index, 1); // remove 1 element from index
          setSelectedBugs(valoresBugs);
        }
      }
    }
  };

  const handleOnChangeRange = (event) => {
    // let cancel;

    const leer = async () => {
      try {
        setLoading(true);
        // console.log();
        // console.log(selectedBugs);
        // console.log(opcionTipo);
        const filtradoPorTipo = bugs.filter((bug) => {
          return (
            bug.type === opcionTipo && selectedBugs.includes(`${bug.bugId}`)
          );
        });

        // console.log('filtrado', filtradoPorTipo);
        // console.log('no filtrado', bugs);
        // const valores = filtradoPorTipo.map((bug) => {
        const valores = filtradoPorTipo.map((bug) => {
          // console.log('entro');
          return axiosInstance.get(
            `/samples/qr/${bug.bugId}`
            // {
            //   headers: {
            //     authorization: `Bearer ${auth.user.token}`,
            //   },
            // }
            // {
            //   cancelToken: new axios.CancelToken((c) => (cancel = c)),
            // }
          );
        });

        const result = await Promise.all(valores);
        // console.log('RESULTADO', result);
        const samples = result.map((elem) => ({
          name: `${
            bugs.find((x) => x.bugId * 1 === elem.data.data.bugId * 1).ubicacion
          } [${elem.data.data.bugId}]`,
          ubicacion: bugs.find((x) => x.bugId * 1 === elem.data.data.bugId * 1)
            .ubicacion,
          alert: bugs.find((x) => x.bugId * 1 === elem.data.data.bugId * 1)
            .alert,
          tipo: bugs.find((x) => x.bugId * 1 === elem.data.data.bugId * 1).type,
          stats: elem.data.data.stats,
          // name: `id: ${elem.data.data.bugId}`,
          data: elem.data.data.samples.map((muestra) => ({
            x: muestra.timestamp_ms,
            // x: new Date(elem.timestamp_ms).toLocaleString(),
            y: muestra.value != null ? muestra.value : undefined,
          })),
        }));

        // solo los buugs con lecturas
        const samplesFiltrado = samples.filter((item) => {
          return item.data.length > 0;
        });
        // console.log(samples);
        // console.log(samplesFiltrado);
        setSeries(samplesFiltrado);
        setLoading(false);
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          let mensaje = 'Error de conexión, vuelva mas tarde. (500)';
          // console.log('1', error.response);
          if (err.response.status === 401) {
            // console.log(error);
            if (err.response.data.data && err.response.data.data.code === 7) {
              mensaje = 'Email no confirmado, por favor revise su casilla';
            } else {
              mensaje = 'Usuario o contraseña incorrectos';
            }
          }

          setError(mensaje);
          // console.log('1', error.response);
        } else if (err.request) {
          // client never received a response, or request never left
          //console.log('2', error.request);
          setError('Error de conexión, vuelva mas tarde.');
        } else {
          // anything else
          //console.log('3', error);
          setError('algo malo pasó.');
        }
        setLoading(false);
        // console.log(error.response.data);

        // setError(error.response.data.message);
        setTimeout(() => {
          setError('');
        }, 5000);
      }
    };
    leer();
  };

  useEffect(() => {
    // console.log(unidad);
    setUnidad(unidades.find((x) => x.tipo === opcionTipo));
    let cancel;
    const leer = async () => {
      try {
        let bugsResp = await axiosInstance.get(`/users/qr/${qrcode}`, {
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        });
        // console.log(bugsResp);

        // console.log(bugsResp.data.data.bugs);
        if (bugsResp.data.data.bugs.length > 0) {
          variables.current = [
            ...new Set(bugsResp.data.data.bugs.map((bug) => bug.type)),
          ];
          // console.log(variables.current);
          setBugs(bugsResp.data.data.bugs);
        }
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          let mensaje = 'Error de conexión, vuelva mas tarde. (500)';
          // console.log('1', error.response);
          if (err.response.status === 401) {
            // console.log(error);
            if (err.response.data.data && err.response.data.data.code === 7) {
              mensaje = 'Email no confirmado, por favor revise su casilla';
            } else {
              mensaje = 'Usuario o contraseña incorrectos';
            }
          }

          setError(mensaje);
          // console.log('1', error.response);
        } else if (err.request) {
          // client never received a response, or request never left
          //console.log('2', error.request);
          setError('Error de conexión, vuelva mas tarde.');
        } else {
          // anything else
          //console.log('3', error);
          setError('algo malo pasó.');
        }
        setLoading(false);
        // console.log(error.response.data);

        // setError(error.response.data.message);
        setTimeout(() => {
          setError('');
          auth.logout(); // si hay problema con cookies que se desloguee
        }, 5000);
      }
    };
    leer();
    return () => cancel();
  }, []);

  return (
    <Contenedor>
      {error && (
        <ErrorMessage>
          <ErrorMsj text={error}></ErrorMsj>
        </ErrorMessage>
      )}

      <SeleccionWrapper>
        <Texto>Seleccione la variable que desea visualizar</Texto>
        <Selecciones>
          {variables.current.map((tipo) => (
            <>
              <Etiqueta key={tipo}>
                <Radio
                  type="radio"
                  id={tipo}
                  name="valor"
                  value={tipo}
                  checked={opcionTipo === tipo ? true : false}
                  onChange={onChangeRadio}
                />
                <RadioPulso></RadioPulso>
                <RadioBoton>
                  <RadioBotonInterno></RadioBotonInterno>
                </RadioBoton>
                <RadioEtiqueta>
                  {unidades.find((x) => x.tipo === tipo).nombre}
                </RadioEtiqueta>
              </Etiqueta>
              <ContenedorUbicaciones>
                {bugs &&
                  bugs.map((bug) => {
                    if (bug.type === tipo) {
                      return (
                        <>
                          <OpcionCheckBox key={bug.bugId}>
                            <input
                              type={'checkbox'}
                              // checked={selectedBugs.includes(bug.bugId)}
                              name={bug.bugId}
                              onChange={onChangeCheckbox}
                              {...(opcionTipo !== tipo
                                ? { disabled: true }
                                : {})}
                            />
                            <div>{`${bug.ubicacion} [${bug.bugId}]`}</div>
                          </OpcionCheckBox>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
              </ContenedorUbicaciones>
            </>
            //  <input type="radio" key={tipo} />
            // El + convierte en string al nro
          ))}
          {/* <p>el valor es {opcionTipo}</p> */}
        </Selecciones>
      </SeleccionWrapper>
      <BotonWrapper>
        <Boton
          type="button"
          onClick={handleOnChangeRange}
          {...(loading || selectedBugs.length < 1 ? { disabled: true } : {})}
        >
          Descargar datos
        </Boton>
      </BotonWrapper>
    </Contenedor>
  );
};

export default ControlesMobileQR;
