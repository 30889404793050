import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Contenedor = styled.div`
  width: 100%;
  height: 100vh;
  background-color: white;
  position: relative;
  overflow: hidden;
  /* z-index: -25; */
`;

const Logo = styled.span`
  color: #e02401;
  /* font-family: 'Archivo Black', sans-serif; */
  font-family: 'Mitr', sans-serif;
  /* font-family: 'Suez One', serif; */
  top: 30px;
  left: 50px;
  font-size: 70px;
  font-weight: 500;
  letter-spacing: 3px;
  position: absolute;
  /* filter: blur(0.7px); */
  @media (max-width: 768px) {
    left: 50%;
    top: 10%;
    transform: translate(-50%, -50%);
  }
`;

const CuadroTexto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: pink; */
  /* width: 50%; */
  position: absolute;
  top: 40%;
  left: 50%;
  text-align: center;
  /* margin-right: -50%; */
  transform: translate(-50%, -50%);
`;

const StatusCode = styled.h2`
  padding: 0;
  margin: 0;
  /* position: relative; */
  width: 900px;
  font-weight: 200;
  font-size: 350px;
  letter-spacing: 3px;
  font-family: 'Mitr', sans-serif;
  color: black;
  /* left: 120px; */
  /* top: 80px; */
  /* filter: blur(0.7px); */

  @media (max-width: 768px) {
    font-size: 55px;
  }
`;

const Subtitulo = styled.h2`
  /* position: relative; */
  padding: 0;
  margin: 0;
  width: 900px;
  font-weight: 400;
  font-size: 50px;
  letter-spacing: 3px;
  font-family: 'Mitr', sans-serif;
  color: black;
  /* left: 120px; */
  /* top: 80px; */
  /* filter: blur(0.7px); */
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Resaltado = styled.span`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border-radius: 8px;
  background: repeating-linear-gradient(
    45deg,
    #ffffff,
    #ffffff 5px,
    #dddddd 5px,
    #dddddd 10px
  );
  padding: 5px 25px;
  color: black;
  font-weight: 400;
  font-size: 50px;
  font-family: 'Mitr', sans-serif;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* filter: blur(0.5px); */
  white-space: nowrap;
  /* transition: all 0.2s; */
  /* outline: none; */
  /* font-weight: 400; */
  &:hover {
    color: #e02401;
    cursor: pointer;

    /* transform: scale(1.05); */
  }
  @media (max-width: 768px) {
    font-size: 35px;
  }
`;

const NotFoundPage = () => {
  const history = useHistory();

  const handleOnClickVolver = (event) => {
    event.preventDefault();

    history.push('/');
  };
  return (
    <>
      <Contenedor>
        <Logo>bamed</Logo>

        <CuadroTexto>
          <StatusCode>404</StatusCode>
          <Subtitulo>página no encontrada</Subtitulo>
        </CuadroTexto>
        <Resaltado onClick={handleOnClickVolver}>volver al inicio</Resaltado>
      </Contenedor>
    </>
  );
};

export default NotFoundPage;
