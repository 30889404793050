import { useState, createContext, useContext } from 'react';

// se crea el contexto
const SeriesContext = createContext();

// creamos un custom hook para simplificxar el proceso
export const useSeriesAndStats = () => useContext(SeriesContext);

// se crea la funcion provider del contexto

function SeriesContextProvider({ children }) {
  const [series, setSeries] = useState();
  // const [stats, setStats] = useState();
  const [loading, setLoading] = useState(false);
  const [unidad, setUnidad] = useState();

  const value = {
    series,
    setSeries,
    loading,
    setLoading,
    unidad,
    setUnidad,
  };

  return (
    <SeriesContext.Provider value={value}>{children}</SeriesContext.Provider>
  );
}

export default SeriesContextProvider;
